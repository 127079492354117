<template>
  <div id="footer">
   <h2> {{ footer }} </h2>  
  </div>
</template>
<script>

export default {
  name: "footer",
  components: {
   
  },
  data() {
    return {
      footer: "WE USE TO BE",
      date: "2020"
    };
  },
};

</script>

<style scoped>
#footer {
  color: rgba(0, 0, 0, 0.479);
  background-color: rgba(255, 255, 255, 0);
  word-spacing: 30px;
  position: fixed;
  text-align: center;
  margin:0px 0px;
  left: 0;
  bottom: 0;
  width: 100%;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

h2 {
  letter-spacing: 1px;
  word-spacing: 1px;
  color: rgba(0,0,0,0.4);
  position: relative;
  padding-top: 0px;
  font-size: 0.8rem;
  font-weight: lighter;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition-duration: 1000ms;
}

@media only screen and (max-width: 1040px) {
  h2 {
    position: relative;
    padding-top: 0px;
    font-size: 0.6rem;
    font-weight: lighter;
  }
  h3{
    margin-top: 40px;
    letter-spacing: 2px;
    word-spacing: 5px;
    font-family: 'Impact', bold;
    position: relative;
    padding-top: 0px;
    font-size: 0.8rem;
    font-weight: lighter;
  }
  
}
</style>