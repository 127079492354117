import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

import Info from './assets/Info.vue'
import Works from './assets/Works.vue'
import Home from './assets/Home.vue'
import Clothes from './assets/Clothes.vue'
import Drawings from './assets/Drawings.vue'
import Interior from './assets/Interior.vue'
import Impressum from './assets/Impressum.vue'

import VuePageTransition from 'vue-page-transition'

Vue.use(VueRouter)
Vue.use(VuePageTransition)

const routes = [
  { path: '/', component: Home ,meta: { transition: 'zoom' }},
  { path: '/works', component: Works,meta: { transition: 'fade-in-up' } }, 
  { path: '/info', component: Info,meta: { transition: 'fade-in-up' } },
  { path: '/clothes', component: Clothes,meta: { transition: 'fade-in-up' }},
  { path: '/drawings', component: Drawings,meta: { transition: 'fade-in-up' }},
  { path: '/interior', component: Interior,meta: { transition: 'fade-in-up' }},
  { path: '/impressum', component: Impressum,meta: { transition: 'fade-in-up' }}

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  el: '#app',
  router,
  render: h => h(App),
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  }
})
