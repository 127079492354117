<template>
  <div id="info">
    <p>About</p>
    <h3>Berlin based artist who passionately crafted unique and limited items from found
       and revived junk and materials. For comissions, sales, art tradings, 
        collaborations,festivals and workshops, please <b class="mailto" href="mailto:hello@weusetobe.com">Email us</b></h3>
    <h2> Our Visions  </h2>
    <h3>Re think Re Use Re Cycle Reduce trash functional sustainable beauty items , handmade with love </h3>
    <div class="contacts">
      <a href="mailto:hello@weusetobe.com">Email us</a>
      <a href="https://www.instagram.com/theendlessgallery/">Instagram</a>
    </div>
  </div>
</template>
<script>

export default {
  name: "info",
  data() {
    return {
      headTitle: "We use to Be"
    };
  },
};

</script>

<style scoped>

.mailto {
  font-size: 0.8rem;
    font-family: 'Special Elite', cursive;

    color:rgba(128, 128, 128, 0.8);
    display: inline;
}

  #info {
    margin:0px 0px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  h2 {
    margin-top:2%;

    position: relative;
    padding-top: 0px;
    font-size: 1.6rem;
    /*font-weight: lighter;*/
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition-duration: 1000ms;
    margin-bottom: 5px;
  }

  h3 {
    margin-top:10px;
    font-size: 1rem;
    font-family: 'Special Elite', cursive;

    color:rgba(55, 55, 55, 0.9);
    display: inline-block;
    align-self: center;
    font-weight: 1;
    width: 300px;
    margin-bottom: 0px;
  }

  p {

    font-size: 1.2rem;
    overflow-wrap: break-word;
    margin-bottom:0px;
  } 

  a {
    
    text-align: center;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 30px;
    font-size: 2rem;
    border-radius: 8px;
    transition-duration: 1s;
    background-color: none;
  }

  a:hover{
    color:black;
    border-radius: 8px;
    border-width: 20px;
    background-color: rgba(128, 128, 128, 0.356);
  }

  .contacts {
    margin-top: 0px;
    text-align: center;
    margin-left: 20px;
  }

  @media only screen and (max-width: 880px) {
    h1 {
      text-align: center;
      font-weight: normal;
      position: relative;
      width: 100%;
      height: auto;
      font-size: 2.5rem;
      font-weight: 800;
      line-height: 1.4;
      padding: 0;
      margin: 0 0 0 0;
    }

    h2 {
      text-align: center;
      position: relative;
      padding-top: 5px;
      font-size: 2.3rem;
      font-weight: lighter;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      transition-duration: 1000ms;
    }

    a {
      font-size: 1.8rem;
      color: #42b983;  
      display: inline-table; 
      text-decoration: none;
      margin-top: 0px;
      margin-right: 25px;
      margin-left: 20px;
    }

    .contacts {
      text-align: center;
      margin-left: 20px;
      margin-top: 40%;
    }
  }
</style>