<template>
  <div id="drawings">
    <h2> {{headTitle}} </h2>
      <div v-for="(image) in images" class="gallery" :key="image">
      <v-lazy-image :src="image" oncontextmenu="return false;"/>
  </div>
  </div>
</template>
<script>
import VLazyImage from "v-lazy-image/v2"

export default {
  name: "drawings",
  components: {VLazyImage},
  data() {
    return {
      headTitle: "Paintings / Ilustrations",
       images: [
        "./src/assets/Images/d1.png",
        "./src/assets/Images/d2.png",
        "./src/assets/Images/d3.png",
        "./src/assets/Images/d4.png",
        "./src/assets/Images/d5.png",
        "./src/assets/Images/d6.png",
        "./src/assets/Images/d7.png",
      ],
    };
  },
};
</script>
<style scoped>

#drawings {
    margin:0;
}

h2 {
  position: relative;
  padding-top: 0px;
  font-size: 1.6rem;
  font-weight: lighter;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition-duration: 1000ms;
}

img {
  -moz-user-select: none;
  width: 28%;
}

.gallery{
  margin-right: 20px;
  display: inline;
}

@media only screen and (max-width: 1040px) {
  h1 {
    text-align: center;
    font-weight: normal;
    position: relative;
    width: 100%;
    height: auto;
    font-size: 1.8rem;
    font-weight: 800;
    line-height: 1.4;
    padding: 0;
    margin: 0 0 0 0;
    }

  h2 {
    margin-top:0px;
    margin-bottom: 0px;
    text-align: center;
    position: relative;
    padding-top: 5px;
    font-size: 1.6rem;
    font-weight: lighter;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition-duration: 1000ms;
  }

  a {
    font-size: 1.2rem;
    color: #42b983;  
    display: inline-table; 
    text-decoration: none;
    margin-right: 25px;
    margin-left: 20px;
  }
  img {
    width: 85%;
  }
}
</style>