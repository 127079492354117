<template>
  <div id="app">
    <h1>{{ headTitle }}</h1>
    <h2>BEAUTYliving - LIVINGbeauty</h2>
   <link href="https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap" rel="stylesheet"> 
     <link  rel="stylesheet" 
            href="https://fonts.googleapis.com/css2?family=Special+Elite">
      <link href="https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap" rel="stylesheet"> 
    
    <vue-page-transition name="fade-in-right">
    <router-view />
    </vue-page-transition>
      <Footer />
      <Slide width="180">
      <router-link to="/"> <span>Home</span>  </router-link>
      <router-link to="/works"> <span>Works</span>  </router-link>
    
     <router-link to="/info"> <span>Infos</span>  </router-link>
     <a href="https://www.instagram.com/theendlessgallery/" onclick="alert(' ❤ Check our Instagram for now while we prepare the shop ❤ :-) ');"><span>Shop</span>
     </a>
       <router-link to="/Impressum"> <span>Impressum</span>  </router-link>
      </Slide>
  </div>
</template>
<script>
import Footer from './assets/Footer.vue'
import { Slide } from 'vue-burger-menu'  

export default {
  name: 'app',
  components: {
    Footer,Slide
  },
  data () {
    return {
      headTitle: 'WE USE TO BE',
    }
  },
}
</script>
<style>

canvas {
  position:absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  z-index: -1;
}

#app {
  background-color:rgba(0,0,0,0);
  font-family: 'Amatic SC', cursive; 
  /*font-family: 'Special Elite', cursive; */
	font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
  text-align: center;
  overflow: hidden;
}
h1 {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  text-align: center;
  font-weight: normal;
  position: relative;
  width: 100%;
  height: auto;
  font-size: 3rem;
	font-weight: 800;
  line-height: 1.4;
  padding: 0;
  margin: 0 0 0 0;
}

h2 {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  margin-top:0px;
  text-align: center;
  position: relative;
  padding-top: 0px;
  font-size: 1rem;
  font-weight: lighter;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition-duration: 1000ms;
  }

h2:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.0.5);
  transform: scale(1.05);
  transition-duration: 1000ms;
}

ul {
  margin: 0px;
  text-align: center;
  display: inline-block;
  list-style-type:none;
  margin-right: 40px;
  word-spacing: 40px;
}

li {
  font-weight: 300;
}
li:hover {
  font-weight: 600;
}

a {
  margin-top: 0px;
  font-size: 1rem;
  color: black;  
  display: inline-table; 
  text-decoration: none;
  border: none;
  transition-duration: 1s;
}

a:hover::after {
  width: 100%;
  
}

img {
  margin-top: 30px;
  margin-bottom: 0px;
}

.Navigation{
  margin-top:0px;
  margin-bottom: 10px;
  font-weight: cursive;

}

@media only screen and (max-width: 800px) {
  h1 {
    text-align: center;
    font-weight: normal;
    position: relative;
    width: 100%;
    height: auto;
    font-size: 3rem;
    font-weight: 800;
    line-height: 1.4;
    padding: 0;
    margin: 0 0 0 0;
  }

  h2 {
    text-align: center;
    position: relative;
    padding-top: 0px;
    font-size: 1rem;
    font-weight: lighter;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition-duration: 1000ms;
  }

  a {
    font-size: 1.4rem;
    color: black;  
    display: inline-table; 
    text-decoration: none;
  }
}


.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 30px;
    left: 18px;
    top: 28px;
    cursor: pointer;
}
.bm-menu {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  height: 100%; /* 100% Full-height */
  /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background-color: rgba(105, 105, 105, 0.99); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 40px; /* Place content 60px from the top */
  transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
}
.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.6em;
}

.bm-item-list > * > span {
  margin: 0;
  font-weight: 200;
  color: #000000;
  margin-left: 5%;
  font-size: 5vh;
}

.bm-overlay {
  background: rgba(182, 182, 182, 0.8);
}
.bm-cross {
  background: #000000;
}
.bm-cross-button {
  height: 28px;
  width: 28px;
}
</style>
