<template>
  <div id="slider">
    <transition-group name="fade" tag="div">
      <div v-for="i in [currentIndex]" :key="i">
        <img :src="currentImg"  @click="next" oncontextmenu="return false;"/>
      </div>
    </transition-group>
  </div>
</template>
<script>
export default {
  name: "Slider",
  data() {
    return {
      images: [
        "./src/assets/Images/c1.png",
        "./src/assets/Images/c2.png",
        "./src/assets/Images/d1.png",
        "./src/assets/Images/d2.png",
        "./src/assets/Images/i1.png",
        "./src/assets/Images/i2.png",
        "./src/assets/Images/i8.png",
        "./src/assets/Images/c7.png",
      ],
      timer: null,
      currentIndex: 0
    };
  },

  mounted: function() {
    this.startSlide();
  },

  methods: {
    startSlide: function() {
      this.timer = setInterval(this.next, 3000);
    },

    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    }
  },

  computed: {
    currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }
  
};
</script>
<style scoped>
#slider {
  margin: 0px;
  padding: 0px;
}

.fade-leave-active {
  transition: all 0.8s ease;
  position: absolute;
  width:100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  transition: all 0.8s ease;
  width:0%;
  opacity: 0;
}

img {
  margin: 0px;
  height:20rem;
}

@media only screen and (max-width: 1040px) {

  img {
    height:22rem;
  }
}

</style>