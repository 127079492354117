<template>
  <div id="works">
    <div class="navLinks">
    <h2 >
      Our Art Works
    </h2>
    <router-link to="/Clothes" tag="a">
        <a>Slow Fashion</a>
    </router-link>
    <p>
    <router-link to="/Interior" tag="a">
        <a>Green Interior</a>
    </router-link>
    </p>
    <p>
    <router-link to="/Drawings" tag="a">
        <a>Paintings</a>
    </router-link>
    </p>
    </div>
    <Slider/>
  </div>
</template>
<script>

import Slider from "./Slider.vue";

export default {
  name: "works",
  components: {
    Slider
  },
  data() {
    return {
      headTitle: "We use to Be"
    };
  },
};

</script>

<style scoped>

  #works {
    margin:0px;
    padding: 0px;
    word-spacing: 30px;
  }
  a {
    display: inline-table;
    color:rgba(0,0,0,0.9);
  }
  a:hover {
    color:rgba(0,0,0,1);
  }

  h2 {
    margin-top:0px;
    margin-bottom:5px;
    color: rgba(0,0,0,0.4);
    position: relative;
    padding-top: 0px;
    font-size: 1.6rem;
    font-weight: lighter;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition-duration: 1000ms;
  }
  h3 {
    margin-top: 10px;
    letter-spacing: 2px;
    word-spacing: 10px;
    font-family: 'Amatic SC', cursive; 
    /*font-family:'Special Elite',sans-serif;*/
    position: relative;
    padding-top: 0px;
    font-size: 1rem;
    font-weight: lighter;
  }

  a {
    display: inline-block;
    /*margin-top: 40px;*/
    letter-spacing: 2px;
    word-spacing: 10px;
    font-family: 'Amatic SC', cursive; 
    position: relative;
    padding-top: 0px;
    font-size: 1.5rem;
    font-weight: lighter;
  }
  .navLinks{
    margin-top:0px;
    margin-bottom: 0px;
  }
  
  @media only screen and (max-width: 880px) {

    .navLinks{
      margin-bottom: 10px;
    }

    h2 {
      position: relative;
      padding-top: 0px;
      font-size: 1.8rem;
      font-weight: lighter;
    }
    h3{
      margin-top: 20px;
      letter-spacing: 2px;
      word-spacing: 5px;
      font-family: 'Impact', bold;
      position: relative;
      padding-top: 0px;
      font-size: 0.8rem;
      font-weight: lighter;
    }
    img {
      margin-bottom: 10px;
    }

    a {
      margin-top: 4px;
      letter-spacing: 1px;
      word-spacing: 5px;
    }

    p {
      margin: 0px;
      padding: 0px;
    }
  }
</style>