<template>
  <div id="home">
   <h3>Berlin based artist who passionately crafted unique and limited items from found
       and revived materials</h3>
  </div>
</template>
<script>
export default {
  name: "home",
  data() {
    return {
      headTitle: "We use to Be"
    };
  },
};
</script>
<style scoped>
#home {
    margin:0;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

h2 {
   position: relative;
  padding-top: 0px;
  font-size: 1.2rem;
  font-weight: lighter;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition-duration: 1000ms;
}
h3 {
  margin-top: 5vh;
  display: inline-block;
  width: 200px;
  position: relative;
  padding-top: 0px;
  font-size: 2.2rem;
  font-weight: lighter;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition-duration: 1000ms;

}

</style>